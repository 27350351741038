@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,500,600');
html,
body.oztix,
.oztix input,
.oztix select,
.oztix textarea,
.oztix .pure-g [class*='pure-u'] {
    /* Set your content font stack here: */
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

.oztix h1,
.oztix h2,
.oztix h3,
.oztix h4,
.oztix h5,
.oztix h6 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}
.settings-input {
    width: 30px;
    height: 30px;
}

.oztix .logo {
    background-size: contain;
    width: 110px;
    max-height: 38px;
    padding-bottom: 0;
}

.oztix .theme-localtix .logo {
    width: auto;
    height: auto;
    max-height: 32px;
}

@media screen and (max-width: 470px) {
    .oztix .logo {
        max-width: 90%;
    }
}

@media screen and (max-width: 340px) {
    .oztix .logo {
        max-width: 70%;
    }
}
